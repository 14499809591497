import { Component, Input, OnChanges, SimpleChanges, ViewChildren } from '@angular/core';
import { CustomerMessageComponent } from './customer-message/customer-message.component';

@Component({
  selector: 'app-customer-messages',
  templateUrl: './customer-messages.component.html',
  styleUrls: ['./customer-messages.component.scss'],
})
export class CustomerMessagesComponent implements OnChanges {
  @Input()
  customerMessages: string[] = [];

  @ViewChildren('customerMessage')
  childMessages!: CustomerMessageComponent[];

  ngOnChanges(changes: SimpleChanges) {
    // Set all displayed messages to a collapsed presentation on render
    window.requestAnimationFrame(() => {
      this.childMessages.forEach((customerMessage: CustomerMessageComponent) => {
        customerMessage.setExpanded(false);
      });
    });
  }
}
