import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic([])
  .bootstrapModule(AppModule)
  .catch((err: Error) => {
    // User login error means they are going to get redirect and we don't want to show the error here.
    if (!err.message.match('User login is required.')) {
      document.getElementById('init-error').classList.add('show');
    }
    console.error(err);
  });
