import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-customer-message',
  templateUrl: './customer-message.component.html',
  styleUrls: ['./customer-message.component.scss'],
})
export class CustomerMessageComponent {
  @Input()
  customerMessage: string;

  @ViewChild('setExpandedToggle')
  setExpandedToggle: ElementRef;

  isExpanded: boolean;

  toggleExpanded() {
    this.setExpandedToggle.nativeElement.focus();
  }

  setExpanded(expanded: boolean) {
    this.isExpanded = expanded;
  }
}
