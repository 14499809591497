import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Test } from '../../interfaces/test.interface';
import { Comment, CommentsComponent, GlobalErrorHandlerService } from '@lims-common-ux/lux';
import { AppStateService } from '../../app-state.service';
import { AccessionService } from '../../accession/accession.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent {
  @Input()
  test: Test;

  @Input()
  isExpanded = false;

  @Output()
  conflictError = new EventEmitter<true>();

  @ViewChild('testComments')
  testComment: CommentsComponent;

  @ViewChild('testWrapper', { static: false })
  cmpWrapper: ElementRef;

  commentSearchUrl: string;

  constructor(
    public appStateService: AppStateService,
    private accessionService: AccessionService,
    private globalErrorHandlerService: GlobalErrorHandlerService
  ) {}

  onAddComment(comment: Comment) {
    // Prevent calls to backend when a comment already exists on an assay
    const duplicateComments =
      comment && comment.id && this.test.comments.filter((assayComment) => comment.id === assayComment.id);
    if (!this.appStateService.loading && duplicateComments && duplicateComments.length < 1) {
      if (this.test?._links?.addComment) {
        this.appStateService.loading = true;

        this.accessionService.addTestComment(this.test, comment.id).subscribe({
          complete: () => {
            this.removeLoadingAndFocusComment();
          },
          error: (error) => {
            this.handleError(error);
          },
        });
      }
    }

    this.testComment.resetComments();
  }

  onRemoveComment(comment: Comment) {
    if (!this.appStateService.loading) {
      this.appStateService.loading = true;

      this.accessionService.deleteTestComment(this.test, comment.id).subscribe({
        next: () => {
          setTimeout(() => {
            this.removeLoadingAndFocusComment();
          }, 0);
        },
        error: (error) => {
          this.focusComment();
          this.handleError(error);
        },
      });
    }
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  suppressFocusIn($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
  }

  onFocusIn($event) {
    $event.preventDefault();

    setTimeout(() => {
      if (this.cmpWrapper.nativeElement.contains(document.activeElement) && !this.isExpanded) {
        this.isExpanded = true;
        setTimeout(() => {
          this.focusComment();
        }, 0);
      }
    }, 0);
  }

  onFocusOut($event) {
    $event.preventDefault();

    setTimeout(() => {
      if (
        (this.isExpanded && document.activeElement === document.body) ||
        !this.cmpWrapper.nativeElement.contains(document.activeElement)
      ) {
        if (!this.appStateService.loading) {
          this.isExpanded = false;
        } else {
          this.focusComment();
        }
      }
    }, 0);
  }

  private removeLoadingAndFocusComment() {
    this.appStateService.loading = false;

    setTimeout(() => {
      this.isExpanded = true;
      this.focusComment();
    });
  }

  private focusComment() {
    setTimeout(() => {
      if (this.testComment && !this.test.canceled && this.isExpanded && !!this.test._links?.addComment) {
        this.testComment.focusSearchInput();
      }
    }, 0);
  }

  private handleError(err) {
    this.globalErrorHandlerService.handleError(err);
  }
}
